import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container, Box } from "@mui/material";
import DateConverter from "./DateConverter";
import axiosInstance from "../../app/axiosInstance";
import ToastNetworkError from "./ToastNetworkError";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const token = useSelector((state) => state.authorization.token);
  const [data, setData] = useState();
  const [orderGoods, setOrderGoods] = useState();
  const user = useSelector((state) => state.authorization.userData);
  let itemsCount;

  useEffect(() => {
    setData(props?.data);
    setOrderGoods(props?.data?.order_goods_details);
  }, []);

  if (data) {
    itemsCount = data?.order_goods_details.reduce(
      (sum, item) => sum + item?.quantity_ordered,
      0
    );
  }

  return (
    <div ref={ref}>
      {data && (
        <Container maxWidth="md">
          <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Receipt
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Order#: {data?.order_no}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Date: {DateConverter(data?.date_added)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Authorizer: {data?.cash_authorizer}
                </Typography>
              </Box>
              <Box textAlign="center">
                <Typography variant="h5">{user?.user_company}</Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  COMPANY
                </Typography>
              </Box>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Total Items</TableCell>
                    <TableCell>{itemsCount && itemsCount}</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderGoods &&
                    orderGoods.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.goods_number}</TableCell>
                        <TableCell>{item.quantity_ordered}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box textAlign="center" mt={3}>
              <Typography variant="body2" color="textSecondary">
                ultramanager.live
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Tanzania
              </Typography>
              <Typography variant="body2" color="textSecondary">
                © 2024 Copyright
              </Typography>
            </Box>
          </Paper>
        </Container>
      )}
    </div>
  );
});
