import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/footer";
import Icon from "@mdi/react";
import { mdiViewDashboard } from "@mdi/js";
import Table from "../Components/table/Table";
import StockLayout from "../Components/stock/StockLayout";
import { setFilteredPosition } from "../../app/AuthSlice";
import Statistics from "../Components/statistic/Statistics";
import PageHeader from "../Components/pageHeader";

function MainPanel() {
  const dispatch = useDispatch();
  dispatch(setFilteredPosition("all"));
  const dropDownTime = ["Daily", "Weekly", "Monthly", "Yearly"];

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <PageHeader PageName="Dashboard" PageIcon={mdiViewDashboard} />
        <Statistics />
        <Table isDashboard={true} />
        <StockLayout title={"recent added products"} isDashboard={true} />
      </div>
      <Footer />
    </div>
  );
}

export default MainPanel;
