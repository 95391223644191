import React, { useEffect, useState } from "react";
import { mdiDelete, mdiEye } from "@mdi/js";
import Icon from "@mdi/react";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "reactstrap";
import NotifyMsg from "../NotifyMsg";
import { toast } from "react-toastify";
import axiosInstance from "../../../app/axiosInstance";
import { useSelector } from "react-redux";

function TableData({
  column1Data,
  column2Data,
  column3Data,
  column4Data,
  isDashboard,
  dateAdded,
  sendData,
}) {
  const [to, setToUrl] = useState("/");
  const token = useSelector((state) => state.authorization.token);
  const savedOrders = useSelector((state) => state.authorization.savedOrders);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isDashboard) {
      setToUrl("/order-view");
    }
  }, []);

  //set the color class for status column
  const setColorClass = (data) => {
    let statusColor = "";
    if (data === "pending") {
      statusColor = "info";
    }
    if (data === "canceled") {
      statusColor = "danger";
    }
    if (data === "paid") {
      statusColor = "success";
    }
    return statusColor;
  };

  const dateConverter = (datePassed) => {
    const date = new Date(datePassed);
    const formatedDate = date.toLocaleDateString("en-US");
    return formatedDate;
  };

  //deleting order
  let deleteId;
  const deleteNotification = (e) => {
    deleteId = parseFloat(e.currentTarget.getAttribute("name"));
    toast(
      <NotifyMsg
        Msg={"Order hii itafutwa katika mfumo, unauhakika na kitendo hiki?"}
        yesMethod={deleteAction}
      />,
      { autoClose: false }
    );
  };
  const deleteAction = async () => {
    toast.dismiss();
    toast.info("Tafadhali Subiri...", { autoClose: false });
    try {
      const response = await axiosInstance.post(
        `/order/delete_order/`,
        {
          order_no: deleteId,
        },
        {
          headers: {
            authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("order imefutwa kikamilifu", { autoClose: true });
        sendData(deleteId);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("kuna tatizo la kimtandao", { autoClose: true });
    }
  };

  const toOrder = () =>
    navigate("/order-view", { state: { orderId: column1Data } });

  const orderPercent = () => {
    if (savedOrders.length > 0) {
      const order = savedOrders?.filter(
        (item) => item.order_no === parseInt(column1Data)
      )[0];

      //calculating the percentage issued items
      const arr = [];
      const total = order?.order_goods_details?.length;
      order?.order_goods_details?.map((item) => {
        item.issued_status && arr.push(item);
      });
      const ratio = (arr.length / total) * 100;

      if (!isNaN(ratio)) {
        return `${ratio}%`;
      } else {
        return "0%";
      }
    }
    return "0%";
  };

  return (
    <tr className="text-start hoverable" style={{ cursor: "pointer" }}>
      <td onClick={toOrder}>
        <div className="bg-transparent">
          <div>
            <img
              alt="table-image"
              src={require("../../images/dashboard/ImageIcon.jpg")}
              style={{
                width: "4rem",
                height: "4rem",
                objectFit: "cover",
              }}
              className="rounded"
            />
          </div>
          {column4Data === "paid" && (
            <div className="pt-1">
              <div
                className="progress"
                style={{ width: "4rem", height: "0.2rem" }}
              >
                <div
                  className={`progress-bar ${
                    orderPercent() === "100%" && "bg-success"
                  } `}
                  role="progressbar"
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: orderPercent() }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </td>
      <td onClick={toOrder}>{dateConverter(dateAdded)}</td>
      <td onClick={toOrder}>{column1Data}</td>
      <td onClick={toOrder}>{column2Data}</td>
      <td onClick={toOrder}>{column3Data ? column3Data : 0}</td>
      <td className="text-center" onClick={toOrder}>
        <Badge color={setColorClass(column4Data)} className="text-white">
          {column4Data}
        </Badge>
      </td>
      <td>
        {!isDashboard && (
          <div className="btn btn-group p-0 m-0 bg-transparent border-0">
            <a className="text-decoration-none btn">
              <Link
                to={"/order-view"}
                className="nav-link"
                state={{ orderId: column1Data }}
              >
                <i className="text-success p-3">
                  <Icon path={mdiEye} size={0.8} />
                </i>
              </Link>
            </a>

            <div>
              <a
                className="text-decoration-none btn"
                onClick={deleteNotification}
                name={column1Data}
              >
                <i className="text-danger p-3">
                  <Icon path={mdiDelete} size={0.8} />
                </i>
              </a>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
}

export default TableData;
