import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../app/axiosInstance";
import { mdiChevronDown, mdiStore } from "@mdi/js";
import Footer from "../Components/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setSavedStores } from "../../app/AuthSlice";
import { setSavedGoodsTypes, setSavedGoodsUpdate } from "../../app/StockSlice";
import PageHeader from "../Components/pageHeader";
import NotifyMsg from "../Components/NotifyMsg";
import API from "../../services/api/API";
import ToastNetworkError from "../Components/ToastNetworkError";
import AddedImageTile from "./AddedImageTile";
import {
  Box,
  TextField,
  MenuItem,
  Skeleton,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize,
} from "@mui/material";
import Icon from "@mdi/react";
import helperFunctions from "../Components/helpers";

function ItemForm() {
  const { state } = useLocation();
  const token = useSelector((state) => state.authorization.token);
  const savedGoodsTypes = useSelector(
    (state) => state.stockStore.savedGoodsTypes
  );
  const savedStores = useSelector((state) => state.authorization.savedStores);
  const user = useSelector((state) => state.authorization.userData);
  const navigate = useNavigate();
  const [goodsType, setGoodsType] = useState([]);
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numberValue, setValue] = useState();
  const [selectedItem, setSelectedItem] = useState("");
  const dispatch = useDispatch();
  const [activeStore, setActiveStore] = useState({
    store: [],
  });
  const [itemNumberData, setItemNumberData] = useState({
    quantityAdded: 0,
    buyingPrice: 0,
    lowerPriceMargin: 0,
    sellingPrice: 0,
  });
  const [itemFormData, setItemFormData] = useState({
    goodstype: "",
    goodsName: "",
    goodsNumber: "",
    quantityAdded: 0,
    store: "",
    unit_preferred: "PIECES",
    buyingPrice: 0,
    lowerPriceMargin: 0,
    sellingPrice: 0,
    goodsNotes: "",
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [isRestricted, setIsRestricted] = useState(false);
  const unitArray = ["PIECES", "PAIR", "DOZEN", "CRATE", "GROSS"];

  useEffect(() => {
    //fill the ItemForm Data with the instance data if the request come for editing
    const instanceItemId = state && state;
    if (state) {
      const unit = state?.unit_preferred?.toUpperCase();

      // Calculate values once and reuse
      const quantityAddedPromise = helperFunctions.PiecesToUnit({
        unit,
        pieces_quantity: state?.goods_quantity_in_pieces,
      });

      const buyingPricePromise = helperFunctions.PriceToUnitPrice({
        unit,
        pricePerPiece: state?.piece_buying_price,
      });

      const lowerPriceMarginPromise = helperFunctions.PriceToUnitPrice({
        unit,
        pricePerPiece: state?.piece_lower_price_margin,
      });

      const sellingPricePromise = helperFunctions.PriceToUnitPrice({
        unit,
        pricePerPiece: state?.piece_selling_price,
      });

      const instanceData = {
        goodstype: state?.goods_type,
        goodsName: state?.goods_name,
        goodsNumber: state?.goods_number,
        unit_preferred: state?.unit_preferred,
        quantityAdded: quantityAddedPromise,
        store: state?.store,
        buyingPrice: buyingPricePromise,
        lowerPriceMargin: lowerPriceMarginPromise,
        sellingPrice: sellingPricePromise,
        goodsNotes: state?.goods_notes,
      };
      setItemFormData(instanceData);

      const instanceNumberField = {
        quantityAdded: quantityAddedPromise.toLocaleString(),
        buyingPrice: buyingPricePromise.toLocaleString(),
        lowerPriceMargin: lowerPriceMarginPromise.toLocaleString(),
        sellingPrice: sellingPricePromise.toLocaleString(),
      };
      setItemNumberData(instanceNumberField);

      setActiveStore({ ...activeStore, store: [state.store] });
      setIsRestricted(state?.restricted_price);
    }

    //Loading the goodsTypes or fetching them for the dropdown selection of category
    if (savedGoodsTypes?.length > 0) {
      const arr = [];
      savedGoodsTypes.map((item) => arr.push(item.goods_type));
      setGoodsType(arr);
      setIsLoading(false);
    } else {
      const goodsTypeFetch = async () => {
        const response = await API.goodsTypeFetch(token);
        if (!response) {
          ToastNetworkError();
        }
        if (response.status === 200) {
          dispatch(setSavedGoodsTypes(response.data.results));
          const arr = [];
          response.data.results.map((item) => arr.push(item.goods_type));
          setGoodsType(arr);
          setIsLoading(false);
        }
      };
      goodsTypeFetch();
    }

    //Loading the Stores from cache or fetching the store available in the company for the radio selection in selecting shops
    if (savedStores?.length > 0) {
      const arr = [];
      savedStores.map((item) => arr.push(item.store_name));
      setStores(arr);
    } else {
      const storeFetch = async () => {
        try {
          const response = await API.StoreFetch(token);
          dispatch(setSavedStores(response.data.result));
          const arr = [];
          response.data.result.map((item) => arr.push(item.store_name));
          setStores(arr);
        } catch (error) {}
      };
      storeFetch();
    }
  }, []);

  //notifications handling on Sending the Data to the API
  const toastId = useRef(null);

  //sending Data to the API through notification confirmation
  const addItemToServer = async () => {
    let data = {
      ...itemFormData,
      ["token"]: token,
      ["state"]: state,
      ["store"]: activeStore?.store[0],
      ["images"]: selectedImages,
      ["restricted_price"]: isRestricted,
    };

    const response = await API.postItem(data);
    if (!response) {
      ToastNetworkError();
    }
    if (response.status === 201 || response.status === 200) {
      dispatch(setSavedGoodsUpdate([]));
      toast.dismiss(toast.current);
      navigate("/stock", {
        state: { isAdded: true },
      });
      //  navigate("/stock", {
      //    state: { isAdded: true, isEdited: response.status === 200 },
    } else {
      ToastNetworkError();
    }
  };

  const Msg = ({ closeToast }) => (
    <div>
      Umehakiki Taarifa Zako na upo Tayari kuzituma?
      <div>
        <button className="btn btn-success me-2" onClick={addItemToServer}>
          Ndio
        </button>
        <button onClick={closeToast} className="btn btn-danger me-2">
          Hapana
        </button>
      </div>
    </div>
  );

  const Cancel = ({ closeToast }) => (
    <div>
      rudi katika page iliyopita?
      <div>
        <button className="btn btn-success me-2" onClick={() => navigate(-1)}>
          Ndio
        </button>
        <button onClick={closeToast} className="btn btn-danger me-2">
          Hapana
        </button>
      </div>
    </div>
  );

  const notify = () => (toastId.current = toast(<Msg />));

  const notifyCancel = () => toast(<Cancel />);

  //handling input data
  const handleInputData = (e) => {
    const { name, value } = e.target;
    const filteredValues = value?.split(",")?.length;
    let numericValue = value;
    if (filteredValues > 1) {
      const num = value.replace(/,/g, "");
      numericValue = parseFloat(num);
    }

    if (!isNaN(numericValue) || value === "") {
      const displayedData =
        value === "" ? "" : parseInt(numericValue).toLocaleString();
      setItemNumberData({ ...itemNumberData, [name]: displayedData });
      setItemFormData({
        ...itemFormData,
        [name]: isNaN(numericValue) ? "" : numericValue,
      });
    } else {
      setItemFormData({ ...itemFormData, [name]: value });
    }
  };

  //handling category dropdown to obtain selected goodsType category
  const handleDropDownData = (item) => {
    setSelectedItem(item);
    setItemFormData({ ...itemFormData, ["goodstype"]: item });
  };

  //handling Radio button check to obtain the selected store for the added good
  const handleRadioButton = (e) => {
    if (e.target.checked) {
      const storeArr = activeStore.store;
      storeArr.push(e.target.name);
      setActiveStore({ ...activeStore, ["store"]: storeArr });
    } else {
      let storeArr = activeStore.store;
      storeArr = storeArr.filter((item) => item !== e.target.name);
      setActiveStore({ ...activeStore, ["store"]: storeArr });
    }
  };

  //handling unit Selection
  const handleUnitChange = (item) => {
    const changingData = { ...itemFormData };
    changingData["unit_preferred"] = item.toUpperCase();

    //change the quantity and prices based on unit change
    const unit = item.toUpperCase();

    if (state) {
      const quantityAddedPromise = helperFunctions.PiecesToUnit({
        unit,
        pieces_quantity: state?.goods_quantity_in_pieces,
      });

      const buyingPricePromise = helperFunctions.PriceToUnitPrice({
        unit,
        pricePerPiece: state?.piece_buying_price,
      });

      const lowerPriceMarginPromise = helperFunctions.PriceToUnitPrice({
        unit,
        pricePerPiece: state?.piece_lower_price_margin,
      });

      const sellingPricePromise = helperFunctions.PriceToUnitPrice({
        unit,
        pricePerPiece: state?.piece_selling_price,
      });

      const changeOnData = {
        quantityAdded: quantityAddedPromise,
        store: state?.store,
        buyingPrice: buyingPricePromise,
        lowerPriceMargin: lowerPriceMarginPromise,
        sellingPrice: sellingPricePromise,
      };

      Object.entries(changeOnData).forEach(([key, value]) => {
        setItemFormData({
          ...changingData,
          [key]: value,
        });
      });

      const instanceNumberField = {
        quantityAdded: quantityAddedPromise.toLocaleString(),
        buyingPrice: buyingPricePromise.toLocaleString(),
        lowerPriceMargin: lowerPriceMarginPromise.toLocaleString(),
        sellingPrice: sellingPricePromise.toLocaleString(),
      };
      setItemNumberData(instanceNumberField);
    } else {
      setItemFormData(changingData);
    }
  };

  //submitting form Data to the API
  const [errorFields, setErrorField] = useState({
    quantityAdded: true,
    store: true,
    goodsName: true,
    goodsNumber: true,
    goodstype: true,
    buyingPrice: true,
    lowerPriceMargin: true,
    sellingPrice: true,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...itemFormData,
      ["store"]: activeStore.store[0],
      ["images"]: selectedImages,
    };

    setErrorField(() => {
      const updatedErrorField = { ...errorFields };
      for (const key in data) {
        if (!data[key]) {
          updatedErrorField[key] = false;
        } else {
          updatedErrorField[key] = true;
        }
      }
      return updatedErrorField;
    });

    if (
      !data.goodstype ||
      !data.goodsName ||
      !data.goodsNumber ||
      !data.quantityAdded ||
      !data.buyingPrice ||
      !data.lowerPriceMargin ||
      !data.sellingPrice ||
      !data.store
    ) {
      toast.error("Tafadhari jaza taarifa zote ni za muhimu", {
        autoClose: true,
      });
    } else {
      toast(
        <NotifyMsg
          Msg={"Umehakiki Taarifa Zako na upo Tayari kuzituma?"}
          yesMethod={addItemToServer}
        />,
        {
          autoClose: false,
        }
      );
    }
  };

  //handling image change
  const removeImg = (name) => {
    const remaining = selectedImages.filter((img) => img?.name !== name);
    setSelectedImages(remaining);
  };

  const imageChange = (e) => {
    const file = e.target.files[0];
    const name = file?.name;
    if (selectedImages?.length < 4) {
      if (file) {
        setErrorField({ ...errorFields, ["images"]: false });
        const reader = new FileReader();
        reader.onloadend = () => {
          const arr = [...selectedImages];
          arr.push({ name: name, img: reader.result, file: file });
          setSelectedImages(arr);
        };
        reader.readAsDataURL(file);
      }
    } else {
      toast.error("Unaweza kujaza picha nne Tu kwa bidhaa moja");
    }
  };

  const restrictAction = () => {
    setIsRestricted(!isRestricted);
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <PageHeader
            PageName="Edit Item"
            PageIcon={mdiStore}
            requireBack={true}
          />
          <div>
            <div className="mt-3">
              <div className="h4 p-2 text-secondary">
                Ongeza/Rekebisha Bidhaa
              </div>
            </div>
          </div>
          <div className="row">
            <section>
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body row">
                    <div className="col-md-6">
                      <div className="btn w-100">
                        <input
                          type="file"
                          className="d-none w-100"
                          id="new-item-img"
                          onChange={imageChange}
                        />
                        <label for="new-item-img" className="w-100">
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                            className="w-100"
                          >
                            <img
                              src={
                                selectedImages?.length > 0
                                  ? selectedImages[0]?.img
                                  : require("../images/dashboard/ImageIcon.jpg")
                              }
                              alt="Selected File"
                              id="fileImage"
                              style={{
                                height: "30rem",
                                width: "100%",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            />
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                height: "100%",
                                width: "100%",
                                background: "rgba(0,0,0,0.20)",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <span className="h4 text-white">
                                {selectedImages?.length > 0
                                  ? "Add More Images"
                                  : "Add image"}
                              </span>
                            </div>
                          </div>
                        </label>
                        <div className="my-2"></div>
                        <div>
                          <div className="row w-100 pb-4">
                            {selectedImages?.length > 0 &&
                              selectedImages?.map((item) => (
                                <AddedImageTile
                                  imagePath={item?.img}
                                  action={() => removeImg(item?.name)}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 p-3">
                      <div className="form-group">
                        {isLoading ? (
                          <Skeleton variant="rectangular" height={60} />
                        ) : (
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <div>
                              <TextField
                                id="Category"
                                fullWidth
                                label="Kundi la bidhaa"
                                name="goodstypes"
                                variant="outlined"
                                select
                                defaultValue={
                                  state?.goods_type && state.goods_type
                                }
                              >
                                {goodsType.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={item}
                                    onClick={() => handleDropDownData(item)}
                                  >
                                    {item}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          </Box>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="py-1">
                          <label>
                            Chagua store{" "}
                            {errorFields.store ? (
                              ""
                            ) : (
                              <span className="text-danger">muhimu</span>
                            )}
                          </label>
                        </div>
                        <div className="d-inline-flex gap-2 flex-wrap">
                          {stores.map((store, index) => (
                            <div key={index}>
                              <FormControlLabel
                                control={<Checkbox />}
                                label={store}
                                id={`shop ${index + 1}`}
                                name={store}
                                value={store}
                                checked={activeStore.store.includes(store)}
                                onChange={handleRadioButton}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="form-group d-inline-flex gap-2 w-100">
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            require
                          >
                            <TextField
                              id="aina"
                              label="Aina ya bidhaa"
                              name="goodsName"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputData}
                              value={
                                itemFormData?.goodsName &&
                                itemFormData.goodsName
                              }
                            />
                          </Box>
                        </div>
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="jina"
                              label="Jina la bidhaa"
                              name="goodsNumber"
                              fullWidth
                              variant="outlined"
                              onChange={handleInputData}
                              value={
                                itemFormData?.goodsNumber &&
                                itemFormData.goodsNumber
                              }
                            />
                          </Box>
                        </div>
                      </div>
                      <div className="form-group d-inline-flex gap-2 w-100">
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="itemNumber"
                              type="text"
                              name="quantityAdded"
                              min="0"
                              disabled={state && true}
                              label={`Idadi ya bidhaa (${itemFormData?.unit_preferred.toUpperCase()})`}
                              value={itemNumberData.quantityAdded}
                              fullWidth
                              variant="outlined"
                              onChange={handleInputData}
                            />
                          </Box>
                        </div>

                        <div className="form-group p-0 border-0">
                          {isLoading ? (
                            <Skeleton variant="rectangular" height={60} />
                          ) : (
                            <Box
                              component="form"
                              noValidate
                              autoComplete="on"
                              required
                            >
                              <div>
                                <TextField
                                  id="unitPreferred"
                                  fullWidth
                                  label="Unit"
                                  name="unit_preferred"
                                  variant="outlined"
                                  select
                                  defaultValue={
                                    state?.unit_preferred
                                      ? state.unit_preferred.toUpperCase()
                                      : itemFormData?.unit_preferred?.toUpperCase()
                                  }
                                >
                                  {unitArray.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={item}
                                      onClick={() => handleUnitChange(item)}
                                    >
                                      {item}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            </Box>
                          )}
                        </div>
                      </div>
                      <div className="form-group d-inline-flex gap-2 w-100">
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="beiNunua"
                              label={`Bei ya kununulia (${itemFormData?.unit_preferred.toUpperCase()})`}
                              name="buyingPrice"
                              fullWidth
                              type="text"
                              variant="outlined"
                              onChange={handleInputData}
                              value={itemNumberData?.buyingPrice}
                            />
                          </Box>
                        </div>
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="beiUza"
                              label={`Bei ya Jumla (${itemFormData?.unit_preferred.toUpperCase()})`}
                              name="lowerPriceMargin"
                              fullWidth
                              type="text"
                              variant="outlined"
                              onChange={handleInputData}
                              value={itemNumberData?.lowerPriceMargin}
                            />
                          </Box>
                        </div>
                      </div>
                      <div className="form-group d-inline-flex gap-2 w-100">
                        <div className="form-control p-0 border-0">
                          <Box
                            component="form"
                            noValidate
                            autoComplete="on"
                            required
                          >
                            <TextField
                              id="beiUza"
                              label={`Bei ya kuuzia (${itemFormData?.unit_preferred.toUpperCase()})`}
                              name="sellingPrice"
                              fullWidth
                              type="text"
                              variant="outlined"
                              onChange={handleInputData}
                              value={itemNumberData?.sellingPrice}
                            />
                          </Box>
                        </div>
                      </div>
                      <div className="form-group ">
                        <div>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Isiuzwe chini ya bei"
                            id={"restriction"}
                            name={"restriction"}
                            value={isRestricted}
                            checked={isRestricted}
                            onChange={restrictAction}
                          />
                        </div>
                      </div>

                      <div className="form-group d-inline-flex gap-2 w-100">
                        <div className="form-control p-0 border-0">
                          <Accordion>
                            <AccordionSummary
                              expandIcon={
                                <Icon path={mdiChevronDown} size={1} />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                              fullWidth
                            >
                              Maelezo ya ziada
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="form-control p-0 m-0 border-0">
                                <TextareaAutosize
                                  minRows={2}
                                  fullWidth
                                  className="w-100"
                                  id="goodsNotes"
                                  name="goodsNotes"
                                  value={itemFormData?.goodsNotes}
                                  onChange={handleInputData}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-success me-2">
                        Submit
                      </button>
                      <span className="btn btn-light" onClick={notifyCancel}>
                        Sitisha
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
        <Footer />
        <ToastContainer position="top-right" />
      </div>
    </>
  );
}

export default ItemForm;
