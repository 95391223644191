import React, { useEffect, useState } from "react";
import ItemsData from "./ItemData";
import API from "../requests/api/API";
import ToastNetworkError from "../ToastNetworkError";
import { useDispatch, useSelector } from "react-redux";
import { setSavedGoods } from "../../../app/StockSlice";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { data } from "jquery";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function StockListLayout() {
  const token = useSelector((state) => state.authorization.token);
  const savedGoods = useSelector((state) => state.stockStore.savedGoods);
  const word = useSelector((state) => state.stockStore.searchWord) || "";
  const filteredGoodsType = useSelector(
    (state) => state.authorization.filteredPosition
  );
  const [goods, setGoods] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (savedGoods.length > 0) {
      setGoods(savedGoods);
      setIsLoading(false);
    }

    const goodsFetching = async () => {
      const response = await API.goodsFetch(token);
      if (!response) {
        ToastNetworkError();
      }

      if (response.status === 200) {
        const data = response.data.result;
        setGoods(data);
        dispatch(setSavedGoods(data));
        setIsLoading(false);
      } else {
        ToastNetworkError();
      }
    };
    goodsFetching();
  }, []);

  //filtering data based on DropDown selection and return them as data array
  if (goods) {
    if (filteredGoodsType !== "all") {
      data = goods?.filter((item) => item.goods_type === filteredGoodsType);
    } else {
      data = goods;
    }

    //filtering the search results
    if (word.length > 0) {
      data = data?.filter((item) =>
        item.goods_number?.toLowerCase().includes(word.toLowerCase())
      );
    }
  }

  return (
    <div className="row py-2">
      {data?.length > 0 && (
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body bg-white">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Model</th>
                      <th>Price/piece</th>
                      <th>Stock(pieces)</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{ padding: 0 }}>
                        <div className="table-container">
                          <AutoSizer>
                            {({ height, width }) => (
                              <List
                                height={height}
                                itemCount={data.length}
                                itemSize={100}
                                width={width}
                              >
                                {({ index, style }) => (
                                  <ItemsData style={style} data={data[index]} />
                                )}
                              </List>
                            )}
                          </AutoSizer>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StockListLayout;
