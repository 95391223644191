import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSavedQuantity } from "../../app/AuthSlice";
import axiosInstance from "../../app/axiosInstance";
import { toast } from "react-toastify";
import ToastNetworkError from "../Components/ToastNetworkError";
import { useLocation } from "react-router-dom";
import apiSettings from "../../services/api/API";
import helperFunctions from "../Components/helpers";
import {
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";

function UpdateStockQuantity({ quantity, itemId, lastPreferredUnit }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setModal(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    toggle();
  };
  const { state } = useLocation();
  const token = useSelector((state) => state.authorization.token);
  const user = useSelector((state) => state.authorization.userData);
  const savedQuantity = useSelector(
    (state) => state.authorization.savedQuantity
  );
  const dispatch = useDispatch();
  const [errorFields, setErrorField] = useState({
    quantityAdded: true,
  });
  const [quantityAdded, setQuantityAdded] = useState({
    quantity: 0,
  });
  const [itemNumberData, SetItemNumberData] = useState({
    quantityAdded: 0,
  });
  const [preferredUnit, setPreferredUnit] = useState(lastPreferredUnit);
  //default model
  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (modal) {
      setQuantityAdded({ ...quantityAdded, ["quantity"]: 0 });
      setErrorField({ ...errorFields, ["quantityAdded"]: true });
      SetItemNumberData({ ...itemNumberData, ["quantityAdded"]: 0 });
    }
    setModal(!modal);
  };
  const unitArray = ["PIECES", "PAIR", "DOZEN", "CRATE", "GROSS"];

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setErrorField({ ...errorFields, ["quantityAdded"]: true });
    const filteredValues = value
      .split(" ")
      .filter((item) => item !== "").length;
    let numericValue = value;
    if (filteredValues < 2) {
      numericValue = parseFloat(value.replace(/,/g, ""));
    }
    if (!isNaN(numericValue) || value === "") {
      const displayedData = value === "" ? "" : numericValue.toLocaleString();
      SetItemNumberData({ ...itemNumberData, [name]: displayedData });
      setQuantityAdded({
        ...quantityAdded,
        ["quantity"]: isNaN(numericValue) ? 0 : numericValue,
      });
    }
  };

  const handleSubmit = () => {
    //validation
    const errorUpdates = { ...errorFields };
    const itemsValidation = () => {
      const keys = Object.keys(quantityAdded);
      keys.map((item, index) => {
        const itemName = keys[index];

        quantityAdded[itemName] !== 0
          ? (errorUpdates["quantityAdded"] = true)
          : (errorUpdates["quantityAdded"] = false);
      });
      setErrorField(errorUpdates);
      const errors = Object.keys(errorUpdates);
      return errors.some((error) => errorUpdates[error] === false);
    };

    if (!itemsValidation()) {
      toast("bidhaa zinatumwa, tafadhali subiri...", { autoClose: true });

      const data = {
        goods: itemId,
        updated_by: user.id,
        quantity_added: quantityAdded.quantity,
        unit_preferred: preferredUnit,
        token: token,
      };

      const updateGoodFetch = async () => {
        try {
          const response = await apiSettings.updateItemQuantity(data);
          if (response.status === 201) {
            setOpen(false);
            toast.dismiss();
            toast.success("umefanikiwa kuongeza idadi ya bidhaa kikamilifu", {
              position: "top-right",
              autoClose: true,
            });

            const updatedQuantity = helperFunctions.getPieces({
              unit: preferredUnit,
              quantity: quantityAdded.quantity,
            });
            dispatch(setSavedQuantity(savedQuantity + updatedQuantity));
            toggle();
          }
        } catch (error) {
          toast.dismiss();
          ToastNetworkError();
        }
      };
      updateGoodFetch();
    }
  };

  const handleUnitChange = (item) => {
    setPreferredUnit(item);
  };

  return (
    <div>
      <Button variant="contained" color="success" onClick={handleClickOpen}>
        Update Quantity
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update item quantity</DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            <Typography variant="body2" color="textSecondary">
              Available items in stock:{" "}
              <Typography
                variant="ButtonText"
                color="ButtonText"
              >{`${quantity} ${lastPreferredUnit}`}</Typography>
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="standard-basic"
                variant="outlined"
                type="number"
                margin="dense"
                style={{ marginInline: "10px" }}
                onChange={handleChanges}
                value={itemNumberData.quantityAdded}
                name="quantityAdded"
                error={!errorFields.quantityAdded}
                helperText={
                  !errorFields.quantityAdded ? "Quantity is required" : ""
                }
                autoClose={true}
              />
              <TextField
                id="standard-basic"
                variant="outlined"
                select
                defaultValue={preferredUnit}
                margin="dense"
                style={{ marginInline: "10px" }}
                error={!errorFields.quantityAdded}
                helperText={!errorFields.quantityAdded ? "Select unit" : ""}
              >
                {unitArray.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item}
                    onClick={() => handleUnitChange(item)}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <Typography variant="body2" color="textSecondary">
              Note: When you confirm, changes will be sent instantly
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdateStockQuantity;
