import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import Footer from "../Components/footer";
import { mdiMedicalBag, mdiStore } from "@mdi/js";
import axiosInstance from "../../app/axiosInstance";
import { useSelector } from "react-redux";
import ShopCards from "./ShopCards";
import DateConverter from "../Components/DateConverter";
import PageHeader from "../Components/pageHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Shops() {
  const token = useSelector((state) => state.authorization.token);
  const { state } = useLocation();
  const [shopFetched, setShopFetched] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteState, setDeleteState] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const shops = async () => {
      try {
        const response = await axiosInstance.get("/stock/store/", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setShopFetched(response.data.result);
        setIsLoading(false);
      } catch (error) {
        //handle error here please
      }
    };
    shops();
    window.history.replaceState(null, document.title, window.location.href);
  }, [deleteState]);

  const addStore = () => {
    navigate("/addStore");
  };

  const storeAddSuccessfull = () => {
    toast.success("umefanikiwa kuongeza Store mpya");
  };

  //refreshing the data after delete
  const dataRefresh = (id) => {
    const data = shopFetched.filter((item) => item.id !== parseInt(id));
    setShopFetched(data);
    setDeleteState(!deleteState);
  };

  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <PageHeader PageName="Stores" PageIcon={mdiStore} />
        <div className="d-inline-flex justify-content-end w-100 align-items-center">
          <div className="text-center px-2">
            <a className="text-decoration-none text-white">
              <button className="btn btn-success me-2" onClick={addStore}>
                Add new Store
              </button>
            </a>
          </div>
        </div>
        <div className="container-fluid rounded-top p-3">
          <div className="row">
            {shopFetched.map((item) => (
              <ShopCards
                shopId={item.id}
                shopName={item.store_name}
                shopKeeper={item.store_supervisor}
                shopLocation={item.store_location}
                created={DateConverter(item.date_added)}
                loading={isLoading}
                deleteAction={dataRefresh}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
      {state && state?.shopAdded && storeAddSuccessfull()}
      <ToastContainer position="top-right" />
    </div>
  );
}

export default Shops;
