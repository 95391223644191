import React from "react";

export const helperFunctions = {
  //converting quantity from pieces to whatever the unit passed out
  PiecesToUnit: ({ unit, pieces_quantity }) => {
    try {
      const unitConversions = {
        PIECES: 1,
        PAIR: 2,
        DOZEN: 12,
        CRATE: 24,
        CARTON: 48,
        GROSS: 144,
      };

      // Check if the unit exists in the conversion table
      if (!unitConversions[unit]) {
        throw new Error(`Conversion for unit '${unit}' is not defined.`);
      }

      // Perform the conversion
      const conversionRate = unitConversions[unit];
      const convertedQuantity = parseFloat(
        (pieces_quantity / conversionRate).toFixed(2)
      );
      return convertedQuantity;
    } catch (error) {
      throw error;
    }
  },

  PriceToUnitPrice: ({ unit, pricePerPiece }) => {
    try {
      // Define unit conversion rates
      const unitConversions = {
        PIECES: 1,
        PAIR: 2,
        DOZEN: 12,
        CRATE: 24,
        CARTON: 48,
        GROSS: 144,
      };

      // Check if the unit exists in the conversion table
      if (!unitConversions[unit]) {
        throw new Error(`Conversion for unit '${unit}' is not defined.`);
      }

      // Perform the conversion
      const conversionRate = unitConversions[unit];
      const pricePerUnit = pricePerPiece * conversionRate;

      return parseFloat(pricePerUnit.toFixed(2));
    } catch (error) {
      throw error;
    }
  },

  getPieces: ({ unit, quantity }) => {
    try {
      const unitConversions = {
        PIECES: 1,
        PAIR: 2,
        DOZEN: 12,
        CRATE: 24,
        CARTON: 48,
        GROSS: 144,
      };

      // Check if the unit exists in the conversion table
      if (!unitConversions[unit]) {
        throw new Error(`Conversion for unit '${unit}' is not defined.`);
      }

      // Perform the conversion
      const conversionRate = unitConversions[unit];
      const convertedQuantity = parseFloat(
        (quantity * conversionRate).toFixed(2)
      );
      return convertedQuantity;
    } catch (error) {
      throw error;
    }
  },
};

export default helperFunctions;
